@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../wdx/common';
@import '../wdx/mixins/typography';

.previousOrderCard {
  display: flex;
  align-items: center;
  position: relative;
  gap: 8px;
}

.mobileViewOrderLink {
  display: none;

  @include media-breakpoint-down('sm') {
    display: unset;
    position: absolute;
    width: 100%;
    height: 110%;
    opacity: 0;
    z-index: 1;
  }
}

.statusIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;

  .successIcon {
    color: $colour-success-green;
  }

  .cancelledIcon {
    color: $colour-chia-grey;
  }
}

.previousOrderSummary {
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0;

  .col {
    @include paragraph;
  }

  .status {
    flex-basis: 20%;

    @include media-breakpoint-down('md') {
      display: none;
    }
  }

  .date {
    flex-basis: 20%;

    @include media-breakpoint-down('md') {
      flex-basis: 30%;
    }

    @include media-breakpoint-down('sm') {
      flex-basis: 50%;
    }

    @include media-breakpoint-down('xs') {
      flex-basis: 75%;
    }
  }

  .numberOfItems,
  .total {
    flex-basis: 11%;
    text-align: right;

    @include media-breakpoint-down('md') {
      flex-basis: 16%;
    }

    @include media-breakpoint-down('sm') {
      flex-basis: 20%;
    }

    @include media-breakpoint-down('sm') {
      flex-basis: 25%;
    }
  }

  .numberOfItems {
    @include media-breakpoint-down('xs') {
      display: none;
    }
  }

  .shopFromThisOrder,
  .viewOrder {
    @include paragraph('medium');

    pointer-events: auto;
    text-align: center;
  }

  .shopFromThisOrder {
    flex-basis: 25%;

    @include media-breakpoint-down('md') {
      flex-basis: 35%;
    }

    @include media-breakpoint-down('sm') {
      display: none;
    }
  }

  .viewOrder {
    flex-basis: 13%;

    @include media-breakpoint-down('md') {
      flex-basis: 20%;
    }

    @include media-breakpoint-down('sm') {
      display: none;
    }
  }

}

.actionMobile {
  position: relative;
  display: none;

  @include media-breakpoint-down('sm') {
    display: block;
    color: $colour-earl-grey;
    flex-basis: $ingredients-unit * 10;
    padding-left: $ingredients-unit * 4;
    padding-top: $ingredients-unit;
    text-align: center;
  }

  svg {
    width: $ingredients-gutter;
    height: $ingredients-gutter;
  }
}