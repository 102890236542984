
@import '~@johnlewispartnership/wtr-ingredients/dist/styles/settings/_spacing.scss';@import '~@johnlewispartnership/wtr-ingredients/dist/styles/colours';
@import '../../styles/settings/variables';
@import '../../styles/tools/mixins';
@import '../../styleguide/styleguide';
@import '../../components/wdx/common';
@import '../wdx/mixins/typography';

$icon-size: 28px;

.subTitle {
  @include paragraph('medium');
}

.heading {
  @include title-alert;
  margin-top: 0;
}

.recentOrder {
  @include paragraph;
}

.previousOrderSummary {
  margin-bottom: $ingredients-unit*4;

}

.container {
  .responsive & {
    @include media-breakpoint-up('md') {
      align-items: flex-start;
      display: flex;
      justify-content: space-around;
    }
  }
}

.delivery,
.collection {
  position: relative;
  padding-right: $icon-size * 2;

  .responsive & {
    @include media-breakpoint-up('md') {
      padding: {
        right: 0;
        left: $icon-size * 3;
      }
    }
  }

  &::before {
    color: $colour-chia-grey;
    font: {
      family: WaitroseGlyph;
      size: $icon-size;
    }
    left: auto;
    position: absolute;
    right: 0;
    top: 0;

    .responsive & {
      @include media-breakpoint-up('md') {
        left: 0;
        right: auto;
      }
    }
  }
}

.delivery {
  &::before {
    content: '\e931';
  }
}

.collection {
  &::before {
    content: '\e930';
  }
}

%section {
  padding: {
    left: 0;
    top: $icon-size * .5;
  }
}

.when {
  .responsive & {
    @include media-breakpoint-up('md') {
      flex: 1;
    }
  }
}

.where {
  @extend %section;

  .responsive & {
    @include media-breakpoint-up('md') {
      flex: 1;
      padding: {
        left: $ingredients-gutter;
        top: 0;
      }
    }
  }
}

.cta {
  @extend %section;

  .responsive & {
    @include media-breakpoint-up('md') {
      align-self: center;
      flex: .5;
      padding: {
        left: $ingredients-gutter * .75;
        right: $ingredients-gutter * .25;
        top: 0;
      }
    }
  }
}

.date {
  padding-right: $ingredients-gutter * .5;
}

.time {
  @include media-breakpoint-up('sm') {
    display: inline-block;
  }
}

.address {
  font-weight: 200;
  margin: 0;
}

.button {
  width: 100%;

  @include media-breakpoint-up('sm') {
    width: auto;
  }
}

.viewAll {
  @include paragraph('medium');
  text-decoration: underline;
  float: right;
  margin-bottom: $ingredients-gutter * .5;
}
